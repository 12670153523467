<template>
	<div class="row">
			<div id="content" class="col-sm-12">
				<div class="page-login">
				
					<div class="account-border">
          <div class="well" style="min-height: auto !important;">										

            <div class="panel-body row">
              <div class="col-sm-6" style="padding-left: 0px;">
                <div class="input-group col-sm-3" style="padding-left: 0px;">
                  <h2>Order Details</h2>
                </div>
              </div>
              <div class="col-sm-6" style="padding-right: 0px; text-align: -webkit-right;">
                 <button  v-if="isVisible === false" type="button" class="btn btn-outline-primary" @click="handdleChangeTrackOrder()">Track Return Order</button>&nbsp;
                 <button  v-if="isVisible === true" type="button" class="btn btn-outline-primary" @click="handdleChangeReturnDetails()">Track Return Order</button>
                 <button data-toggle="modal" data-target="#mdlDisputeChat" class="btn btn-primary">Dispute Chat</button>
              </div>
            </div>
            <hr/>

            <div class="row">
              
							<div class="col-sm-4">
								<img width="100px" :src="order[0].simple_product_id.images[0].image" alt="Aspire Ultrabook Laptop" title="Aspire Ultrabook Laptop" class="img-thumbnail">             

                <ul class="product-options clearfix">
                  <li class="radio active">
                    <h4><b>{{ order[0].simple_product_id.product_id.product_name }} / {{ order[0].simple_product_id.attributes[0].attribute_value_id.lable }} / {{  order[0].simple_product_id.attributes[1].attribute_value_id.lable }}</b></h4>
                  </li>

					      	<p class="p-shipping-details"><img src="assets/image/sample_product_images/clock.svg" style="width:24px;" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;30 Days Buyer Protection</p>
			      			<!-- <p class="p-shipping-details"><img src="assets/image/sample_product_images/logistics-delivery-truck-in-movement.svg" style="width:28px;" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dispute Order Status : {{ order[0].dispute_status}}</p> -->
					      	<p class="p-shipping-details"><img src="assets/image/sample_product_images/money.svg" style="width:25px;" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Payment Method: Cash on Delivery</p>

                </ul>
                
              </div>
              
              <div class="col-sm-4" v-if="isVisible === false">
                 <div class="row">
                  <div class="col-sm-12">
                    <h4>Buyer Information</h4>

                    <ul class="product-options clearfix">
                      <li class="radio active">
                        <p>Name : {{ order[0].order_id.user_id.first_name}} {{ order[0].order_id.user_id.last_name}}</p>
                      </li>
                      <li class="radio active">
                        <p>Mobile : {{ order[0].order_id.user_id.mobile}}</p>                    
                      </li>
                      <li class="radio active">
                        <p>Email : {{ order[0].order_id.user_id.email}}</p>                                        
                      </li>
                    </ul>

                  </div>
                </div>  
                <hr/>
                <div class="row">
                  <div class="col-sm-12">
                    <h4>Delivery Information</h4>

                    <ul class="product-options clearfix">
                      <li class="radio active">
                        <p>Name : {{ order[0].delivery_id.first_name}} {{ order[0].delivery_id.last_name}}</p>
                      </li>
                      <li class="radio active">
                        <p>Mobile : {{ order[0].delivery_id.mobile}}</p>                    
                      </li>
                      <li class="radio active">
                        <p>Email : {{ order[0].delivery_id.email}}</p>                                        
                      </li>
                    </ul>

                  </div>
                </div>  
              </div>

              <div class="col-sm-4" v-if="isVisible === false">
                <div class="row">
                  <div class="col-sm-12">
                     <h4>Billing Information</h4>

                    <ul class="product-options clearfix">
                      <li class="radio active">
                        <p>Order Number : {{ order[0].order_id.order_env_number}}</p>
                      </li>
                      <li class="radio active">
                        <p>Billing Name : {{ order[0].order_id.billing_name}}</p>
                      </li>
                      <li class="radio active">
                        <p>Mobile : {{ order[0].order_id.billing_mobile}}</p>                    
                      </li>
                      <li class="radio active">
                        <p>Address : {{ order[0].order_id.address_id.address}} {{ order[0].order_id.address_id.postal_code}}</p>                                        
                      </li>
                      <li class="radio active">
                        <p>Date : {{ order[0].order_id.date}}</p>                                        
                      </li>
                    </ul>
                  </div>
                </div>
                <hr/>
                 <div class="row">
                  <div class="col-sm-12">
                     <h4>Dispute Information</h4>

                    <ul class="product-options clearfix">
                      <li v-viewer class="radio active">
					        			<img width="100px" :src="order[0].images[0]" alt="Aspire Ultrabook Laptop" title="Aspire Ultrabook Laptop" class="img-thumbnail">                                      
                      </li>
                      <li class="radio active">
                        <p>Discribe Reason : {{ order[0].discribe_reason}}</p>
                      </li>
                      <li class="radio active">
                        <!-- <p>Dispute Status : <b>{{ order[0].dispute_status}}</b></p> -->
                        <p>Dispute Status :  <span class="badge"
                          style="border-radius: 4px; font-size: 10px; padding: 4px 6px;" 
                          :style=" order[0].dispute_status === 'pending'  ? 'background: rgb(254, 205, 54); color: black;':  order[0].dispute_status === 'merchanet approve' ? 'background: rgb(77, 161, 48); color: white;':  order[0].dispute_status === 'merchant reject' ? 'background: rgb(225, 83, 55); color: white;':  order[0].dispute_status === 'send to admin' ? 'background: rgb(77, 161, 48); color: white;':  order[0].dispute_status === 'pickup' ? 'background: rgb(254, 205, 54); color: black;':  order[0].dispute_status === 'retuen approve' ? 'background: rgb(77, 161, 48); color: white;':'background: rgb(77, 161, 48); color: white;'"> {{ order[0].dispute_status}} </span> 
                        </p>                    
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="row" v-if="order[0].dispute_status === 'pending' || order[0].dispute_status === 'pickup' || order[0].dispute_status === 'retuen approve'">
                  <div class="col-sm-12">
                    <h4>Action</h4>
                    <ul class="product-options clearfix">
                      <li class="radio active" v-if="order[0].dispute_status === 'pending'">
                        <button type="button" class="btn btn-outline-primary" @click="changeTrackOrder('merchanet approve')">Approve</button>&nbsp;&nbsp;&nbsp;
                        <button type="button" class="btn btn-outline-primary" @click="changeTrackOrder('merchant reject')">Reject</button>
                      </li>
                       <li class="radio active" v-if="order[0].dispute_status === 'pickup'">
                        <button type="button" class="btn btn-outline-primary" @click="changeTrackOrder('retuen approve')">Return Approve</button>&nbsp;&nbsp;&nbsp;
                      </li>
                      <li class="radio active" v-if="order[0].dispute_status === 'retuen approve'">
                        <button type="button" class="btn btn-outline-primary" @click="changeTrackOrder('money pass')">Money Pass</button>&nbsp;&nbsp;&nbsp;
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="col-sm-8" v-if="isVisible === true">
                <ReturnTrackOrder :order="order[0]" />
              </div>

            </div>

          </div>
          
          <div class="bottom-form">
						<div class="row">
							<div class="col-sm-4">
              </div>
              <div class="col-sm-2">
                <label>Items</label>
                <h4>{{ order[0].order_line_id.qty }}</h4>
              </div>
              <div class="col-sm-2">
                <label>Sub Total</label>
                <h4>Rs. {{ order[0].order_line_id.unit_price }}</h4>
              </div>
              <div class="col-sm-2">
                <label>Shipping and Handling fee</label>
                <h4>Rs. {{ order[0].order_line_id.delivery_charge}}</h4>
              </div>
              <div class="col-sm-2">
                <label>Total</label>
                <h4>Rs. {{ order[0].order_line_id.total_charge}}</h4>
              </div>
            </div>
					</div>

          </div>

        </div>
      </div>

      <!-- User Loan Modal -->
      <div class="modal" id="mdlDisputeChat" tabindex="-1" role="dialog" style="right:0px !important; top: auto; bottom: 0px;  left:auto;">
        <div class="modal-dialog" role="document">
          <div class="modal-content" style="border-radius: 0px !important; ">
            <div class="modal-body" style="padding:0px;">
              <div class="row">
                <ChatModal v-bind:title="'Dispute Chat'" v-bind:data="this.chatdata" /> 
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- User Loan Modal End --> 

      
  </div>  
</template>
<script>
import mixinOrder from "@/mixins/merchant/APIOrder"
import ReturnTrackOrder from "./ReturnTrackOrder"

import ChatModal from '@/components/common/chat_modal'

export default {
  mixins: [mixinOrder],
  components: {
    ReturnTrackOrder,
    ChatModal
  },
  data: function () {
    return {
      isVisible: false,
      breadcrumb: [
        {
          text: "Return Order List",
          href: "/merchant-return-orders",
          active: false
        },
        {
          text: "Return Order View",
          href: "#",
          active: true
        }
      ],
      order: {},
      chatdata: []
    }
  },
  computed: {
    oid() {
      let url = window.location.href.split("merchant-return-orders-configure/");
      return url[1];
    }
  },
  created(){
    this.handleBreadcrumb();
    this.getOrderDetails()
  },
  methods: {
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    getOrderDetails: async function() {
      try {
        let responce = await this.getReturnOrderById(this.oid);
        this.order = responce;

        if (this.order[0].dispute_status === 1) {
          this.order[0].dispute_status = "pending"
        } else if (this.order[0].dispute_status === 10) {
          this.order[0].dispute_status = "merchanet approve"
        } else if (this.order[0].dispute_status === 20) {
          this.order[0].dispute_status = "merchant reject"
        } else if (this.order[0].dispute_status === 30) {
          this.order[0].dispute_status = "send to admin"
        } else if (this.order[0].dispute_status === 40) {
          this.order[0].dispute_status = "admin approve"
        } else if (this.order[0].dispute_status === 50) {
          this.order[0].dispute_status = "pickup"
        } else if (this.order[0].dispute_status === 60) {
          this.order[0].dispute_status = "retuen approve"
        } else if (this.order[0].dispute_status === 70) {
          this.order[0].dispute_status = "money pass"
        }

        this.fetchDisputeChat();

      } catch (error) {
        console.log(error); 
      }
    },
    fetchDisputeChat: async function (){
      try { 
        let responce = await this.getDisputeChat(this.order[0]._id);
        this.chatdata = responce;
      } catch (error) {
        console.log(error); 
      }
    },
    sumbitChat: async function (text){
      try {
        let object = {};
        
        object.answer = text;
        if(this.chatdata.length > 0){         
          object._id =  this.chatdata[this.chatdata.length - 1]._id; 
        } else { 

          this.$swal.fire({
            position: 'top-right',
            type: 'error',
            title: "No Questions to answer",
            showConfirmButton: false,
            timer: 1500
          }) 
          return;
        }
        
        let responce = await this.postDisputeChat(object);
        this.fetchDisputeChat(); 
      } catch (error) {
        console.log(error); 
      }
    },
    changeTrackOrder: function(status) {
      let self = this;
      if (status === "merchanet approve") {

        this.$swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Change Status!'
        }).then((result) => {
          if (result.value) {
            self.changeStatusToMerchanetApprove("Approved");
          }
        }) 
        
        // this.$swal.fire({
        //   title: 'Enter Approve Reason',
        //   input: 'text',
        //   inputAttributes: {
        //     autocapitalize: 'off'
        //   },
        //   showCancelButton: true,
        //   confirmButtonText: 'Approve',
        //   showLoaderOnConfirm: true,
        //   preConfirm: (inputxt) => {
        //     if(inputxt == ""){
        //        this.$swal.showValidationMessage(
        //           `Please Enter A Approve Reason!`
        //         )
        //     } else {
        //       return inputxt
        //     }
             
        //   },
        //   allowOutsideClick: () => !Swal.isLoading()
        // }).then((result) => {
        //   if (result.value) {
        //     self.changeStatusToMerchanetApprove(result.value);
        //   }
        // })


        // this.changeStatusToMerchanetApprove();
      } else if (status === "merchant reject") {
        this.$swal.fire({
          title: 'Enter Reject Reason',
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off'
          },
          showCancelButton: true,
          confirmButtonText: 'Reject',
          showLoaderOnConfirm: true,
          preConfirm: (inputxt) => {
            if(inputxt == ""){
               this.$swal.showValidationMessage(
                  `Please Enter A Reject Reason!`
                )
            } else {
              return inputxt
            }
             
          },
          allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.value) {
            self.changeStatusToMerchantReject(result.value);
          }
        })
        // this.changeStatusToMerchantReject();
      } else if (status === "retuen approve") {

        this.$swal.fire({
          title: 'Enter Return Reason',
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off'
          },
          showCancelButton: true,
          confirmButtonText: 'Approve',
          showLoaderOnConfirm: true,
          preConfirm: (inputxt) => {
            if(inputxt == ""){
               this.$swal.showValidationMessage(
                  `Please Enter A Return Reason!`
                )
            } else {
              return inputxt
            }
             
          },
          allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.value) {
            self.changeStatusToMerchantRetuenApprove(result.value);
          }
        })

         
        // this.changeStatusToMerchantRetuenApprove();
      } else if (status === "money pass") {
        this.$swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Change Status!'
        }).then((result) => {
          if (result.value) {
            self.changeStatusToMerchantMoneyPass();
          }
        })
        // this.changeStatusToMerchantMoneyPass();
      }
    },
    changeStatusToMerchanetApprove: async function (reason) {
       try {
        let object = {};
        object._id = this.order[0]._id;
        object.merchant_reason = reason;
        let responce = await this.putChangeStatusMerchanetApproveById(object);
        this.$swal.fire({
          position: 'top-right',
          type: 'success',
          title: "Order status change to merchanet approve!",
          showConfirmButton: false,
          timer: 1500
        })
        this.getOrderDetails();
      } catch (error) {
        console.log(error); 
      }
    },
    changeStatusToMerchantReject: async function (reason) {
      try {
        let object = {};
        object._id = this.order[0]._id;
        object.merchant_reason = reason;
        let responce = await this.putChangeStatusMerchantRejectById(object);
        this.$swal.fire({
          position: 'top-right',
          type: 'success',
          title: "Order status change to merchant reject!",
          showConfirmButton: false,
          timer: 1500
        })
        this.getOrderDetails();
      } catch (error) {
        console.log(error); 
      }
    },
    changeStatusToMerchantRetuenApprove: async function (reason) {
      try {
        let object = {};
        object._id = this.order[0]._id;
        object.merchant_reason = reason;
        let responce = await this.putChangeStatusMerchantRetuenApproveById(object);
        this.$swal.fire({
          position: 'top-right',
          type: 'success',
          title: "Order status change to merchant Return Approve!",
          showConfirmButton: false,
          timer: 1500
        })
        this.getOrderDetails();
      } catch (error) {
        console.log(error); 
      }
    },
    changeStatusToMerchantMoneyPass: async function () {
      try {
        let object = {};
        object._id = this.order[0]._id;
        let responce = await this.putChangeStatusMerchantMoneyPassById(object);
        this.$swal.fire({
          position: 'top-right',
          type: 'success',
          title: "Order status change to merchant Money Pass!",
          showConfirmButton: false,
          timer: 1500
        })
        this.getOrderDetails();
        // this.$router.push({ name: "Merchant Return Orderst" });
      } catch (error) {
        console.log(error); 
      }
    },
    handdleChangeTrackOrder: function() {
      this.isVisible = true;
    },
    handdleChangeReturnDetails: function() {
      this.isVisible = false;
    }
  }
}
</script>
<style scoped>
/* reset Modal */
@media screen and (min-width: 768px) {
	.modal-dialog {
		width: 600px;  
		margin: 0px auto !important;
	}

} 

.radiocontainer input:checked ~ .checkbackground:after {
display: block;
}

.radiocontainer .checkbackground:after {
top: 0px;
left: 0px;
width: 100%;
height: 100%;
border-radius: 5%;
background:#0096aa75;
border:1px solid #000000;
}


.checkbackground {
position:relative;
border-radius: 5%;
/* border:1px solid #666666; */
background-color: #eee;
padding: 8px 10px;
margin-right:10px;
}

.checkbackground:after {
content: "";
position: absolute;
display: none;
}
</style>

